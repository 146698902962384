#sidebar-container {
  width: 264px;
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: scroll;
}

#sidebar-container #footer-label-contaner {
  border-top: 1px solid #efefef;
}

#sidebar-container .footer-label-detail {
  text-align: left;
  padding-top: 15px;
  padding-left: 25px;
  padding-bottom: 15px;
  width: 200px;
}

#sidebar-container .footer-label-detail .company-label {
  color: #5b5b6b;
  font-family: Mulish;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 5px;
}

#sidebar-container .footer-label-detail .company-product-description {
  color: #a0a5b2;
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}

.nav-menu {
  height: 54px;
  display: flex;
  align-items: center;
  padding-left: 35px;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.08em;
  text-align: left;
  color: var(--medium-black);
  position: relative;
}

.nav-menu.active::before {
  content: "";
  background-color: var(--primary-blue);
  height: 54px;
  width: 5px;
  position: absolute;
  left: 0;
}

.nav-menu.active {
  color: var(--primary-blue);
  background-color: #f8fafb;
}

.nav-container-title-container {
  height: 54px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 35px;
  padding-right: 18px;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.08em;
  text-align: left;
  color: var(--medium-black);
}

.nav-container:not(.show) .nav-container-body {
  height: 0;
  overflow-y: hidden;
  transition: 0.2s;
}

.nav-container:not(.show) .nav-menu.active::before {
  display: none;
}

.nav-container.show .nav-container-body {
  transition: 0.2s;
}

.nav-container .nav-menu {
  padding-left: 60px;
}

.nav-container-title-container .anticon-caret-down.show svg {
  transform: rotate(180deg);
}

.nav-container-title-container .anticon-caret-down:not(.show) svg {
  transform: rotate(270deg);
}

.nav-container-title-container .anticon-caret-down.active svg {
  color: var(--primary-blue);
}

.nav-container-title.active {
  color: var(--primary-blue);
  font-weight: 800;
}
