#navbar-container {
  background-color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 35px;
  border-bottom: 2px solid #efefef;
}
.menu-container {
  margin-left: 50px;
}
.menu-container .nav-menu {
  font-size: 16px;
  font-weight: 700;
  color: white;
  padding: 10px 0;
}

.menu-container .nav-menu.active {
  border-bottom: 3px solid white;
}

#navbar-leading {
  display: flex;
  white-space: nowrap;
  align-items: center;
}

#navbar-leading :first-child {
  margin-right: 10px;
}
#navbar-leading #admin-badge {
  margin-left: 15px;
  font-family: Mulish;
  font-size: 12px;
  font-style: italic;
  font-weight: 800;
  line-height: 15px;
  letter-spacing: 0.08em;
  text-align: left;
  color: var(--primary-blue);
  height: 22px;
  width: 67px;
  left: 189px;
  top: 19px;
  border-radius: 13px;
  background-color: #3275fc1a;
  display: flex;
  align-items: center;
  justify-content: center;
}

#navbar-trailing {
  margin-left: 20px;
  display: flex;
  align-items: center;
}

/* style for notification */

#current-user-container {
  display: flex;
  align-items: center;
}

#current-user-container .ant-avatar > img {
  object-fit: none;
}
#current-user-container .ant-avatar {
  /* padding: 8px; */
  display: flex;
  align-items: center;
  justify-content: center;
}


#current-user-container.ant-dropdown-open {
  background-color: #ffffff1a;
  border-radius: 12px;
}

.user-icon {
  background-color: var(--primary-blue) !important;
}

.username-title {
  color: var(--primary-blue);
  white-space: nowrap;
  font-weight: 700;
  line-height: 17.5px;
}

.user-role-title {
  color: #ffffffcc;
  font-weight: 12px;
  line-height: 15px;
  font-weight: 700;
}

.user-profile-dropdown .ant-dropdown-menu {
  border-radius: 12px;
  padding: 15px 0;
}
.user-profile-dropdown .ant-dropdown-menu-item {
  padding: 8px 14px;
}

.user-profile-dropdown .ant-dropdown-menu .dropdown-menu-title {
  color: var(--font-darkgray);
}

.user-profile-dropdown #logout-menu {
  color: #f46436;
}