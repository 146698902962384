.footer-label-detail {
  text-align: center;
}

.company-label {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */
  color: #939393;
}

.company-label {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */
  color: #939393;
}