@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@400;700;800&display=swap");

.App {
  width: 100vw;
  height: 100vh;
  background-color: var(--background-color);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

#content-container {
  overflow-y: auto;
  flex: 1;
}

.pointer {
  cursor: pointer;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.primary-blue {
  color: var(--primary-blue) !important;
}

.primary-green {
  color: var(--primary-green) !important;
}

.ant-pagination-prev button,
.ant-pagination-next button {
  border: none !important;
}

.ant-pagination-prev button:hover,
.ant-pagination-next button:hover {
  color: black !important;
}

.ant-pagination-item {
  border: none !important;
}

.ant-pagination-item-active {
  background-color: #f1f1f1 !important;
  border-radius: 6px !important;
}

.ant-pagination-item a {
  color: var(--font-lightgray) !important;
  font-size: 18px;
  font-weight: 600;
}

.ant-pagination-item-active a {
  color: var(--font-darkgray) !important;
}

.ant-table-pagination.ant-pagination {
  margin-top: 35px !important;
  margin-bottom: 0 !important;
}

.ant-form-item-label > label {
  color: var(--font-darkgray) !important;
}

.unselectable {
  /* Safari */
  -webkit-user-select: none;
  /* Firefox */
  -moz-user-select: none;
  /* IE10+/Edge */
  -ms-user-select: none;
  /* Standard */
  user-select: none;
}

.ant-btn-primary:not(:hover) {
  background-color: var(--primary-blue) !important;
}

.ant-btn-primary > span {
  color: white;
}

.ant-btn-primary:disabled {
  border: none;
  background-color: #3275fc66 !important;
  color: white !important;
}

.ant-btn-link {
  color: var(--title-color) !important;
}

.ant-select-selection-placeholder {
  display: flex !important;
  align-items: center !important;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.ant-input[disabled],
.ant-picker.ant-picker-disabled {
  background-color: #ececec33 !important;
}

button.ant-btn.ant-btn-success {
  background-color: var(--primary-green);
  color: white;
  border: none;
}

button.ant-btn.ant-btn-cancel {
  background-color: var(--primary-dark);
  color: white;
  border: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

#app-body-wrapper {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
}

.blue {
  color: var(--primary-blue);
}

.green {
  color: var(--primary-green);
}

.title-color {
  color: var(--title-color);
}

.lightgray {
  color: var(--font-lightgray);
}

.medium-black {
  color: var(--medium-black);
}

.darkgray {
  color: var(--font-darkgray);
}

.approval-menu-container {
  padding: 30px;
}

.font-prompt {
  font-family: Prompt;
}

.approval-title {
  font-family: Mulish;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  color: var(--medium-black);
  margin-bottom: 30px;
}

.unauthen-screen,
.forgot-password-screen,
.change-password-screen {
  background-color: white;
}
.user-verification-screen,
.login-screen,
.topup-history-screen,
.researcher-screen,
.participant-screen,
.survey-approval-screen,
.cashout-approval-screen,
.cashout-approved-screen,
.reward-approval-screen,
.credit-approval-screen,
.email-subscription-screen,
.export-history-screen {
  background-color: #f3f5f7;
}

.approval-table-container {
  background-color: white;
  padding: 5px 20px 20px 20px;
  border-radius: 8px;
  margin-top: 15px;
}
.approval-table-container .ant-table-tbody > tr > td,
.approval-table-container .ant-table-thead > tr > th {
  border: none;
}
.approval-table-container
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan]):before {
  display: none;
}

/* border radius for table row */
.approval-table-container tr th:first-child,
.approval-table-container tr td:first-child {
  border-radius: 8px 0 0 8px !important;
}

.approval-table-container tr th:last-child,
.approval-table-container tr td:last-child {
  border-radius: 0 8px 8px 0 !important;
}

/* table spacing between row */
.approval-table-container table {
  border-collapse: separate;
  border-spacing: 0 10px !important;
}

/* table header color  */
.approval-table-container table tr th {
  background-color: #f1f2f6 !important;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #939393;
}

/* space between table header and table body */
.approval-table-container tbody:before {
  /* content: " "; */
  display: block;
  height: 1px;
}

.approval-table-container .review-approval-button {
  height: 38px;
  width: 156px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
}
.approval-table-container .export-download-button {
  height: 38px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
}

.approval-detail-box-container .ant-picker,
.approval-detail-box-container .ant-input-affix-wrapper,
.approval-detail-box-container .ant-form-item-control-input-content input {
  border-radius: 8px;
  height: 40px;
  background-color: #ffffff !important;
}

.approval-detail-box-container .ant-picker-disabled:not(:hover) {
  border-color: #f4f4f4 !important;
}

.approval-detail-box-container
  .ant-form-item:not(.disabled)
  .ant-input-group-addon {
  border-color: #d9d9d9 !important;
}

.approval-detail-box-container .ant-input-group-addon {
  border-color: #f4f4f4 !important;
  color: #939393;
  background-color: white;
  border-radius: 8px 0 0 8px;
}

.approval-detail-box-container .ant-picker {
  width: 100%;
}
.approval-detail-box-container .ant-picker input {
  background-color: transparent !important;
}

.approval-detail-box-container .ant-picker input:disabled {
  color: #939393 !important;
  font-weight: 600 !important;
}

.approval-detail-box-container .ant-input-affix-wrapper {
  padding-top: 0;
  padding-bottom: 0;
  border-color: #f4f4f4;
}

.approval-detail-box-container .ant-input-affix-wrapper input {
  height: 100%;
}

.approval-detail-box-container .ant-input-prefix {
  border-right: 1px solid #f4f4f4;
  padding-right: 10px;
  font-family: Mulish;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #939393;
  margin-right: 10px;
}

.approval-detail-box-container .ant-form-item-label label {
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}

.approval-detail-box-container .ant-form-item-label {
  padding-bottom: 5px !important;
}

.approval-detail-box-container .ant-row.ant-form-item {
  margin-bottom: 12px;
}

.approval-detail-box-container .ant-input[disabled] {
  color: #939393;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  border-color: #f4f4f4;
}

/* approval detail style */
.approval-detail-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  color: var(--medium-black);
}

.approval-detail-box-container {
  margin-top: 30px;
  background-color: white;
  box-shadow: 0px 0px 20px 0px #001f700a;
  width: 100%;
  border-radius: 8px;
  padding: 40px;
}

.approval-detail-name {
  font-size: 36px;
  font-weight: 700;
  line-height: 45px;
  letter-spacing: 0em;
}

.back-to-top {
  background-color: var(--primary-blue);
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: fixed;
  bottom: 50%;
  right: 40px;
  cursor: pointer;
}

.approval-section-header {
  height: 48px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 800;
  line-height: 20px;
  letter-spacing: 0.04em;
  text-align: left;
  background-color: #f7f9fb;
  color: var(--medium-black);
  padding: 15px 15px 15px 20px;
}

button.ant-pagination-item-link {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

li.ant-pagination-item {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

/* img {
  display: unset !important;
} */

/* Customized scrollbar */
*::-webkit-scrollbar {
  background: transparent !important;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
*::-webkit-scrollbar-track {
  background: transparent !important;
}

/* scrollbar itself */
*::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
*::-webkit-scrollbar-button {
  display: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

hr.card-divider {
  height: 1px;
  background-color: var(--line-grey);
  border: none;
  width: 100%;
}

#content-container.home-screen > *,
#content-container.researcher-screen > *,
#content-container.participant-screen > *,
#content-container.user-verification-screen > *,
#content-container[class="survey-approval-screen"] > *,
#content-container.cashout-approval-screen > *,
#content-container.cashout-approved-screen > *,
#content-container.export-history-screen > *,
#content-container.reward-approval-screen > *,
#content-container.credit-approval-screen > *,
#content-container.email-subscription-screen > *,
#content-container.topup-history-screen > * {
  margin-left: auto;
  margin-right: auto;
}

#content-container.home-screen > * {
  min-width: 900px;
  max-width: 1400px;
}

#content-container.researcher-screen > *,
#content-container.participant-screen > *,
#content-container.user-verification-screen > *,
#content-container[class="survey-approval-screen"] > *,
#content-container.cashout-approval-screen > *,
#content-container.cashout-approved-screen > *,
#content-container.export-history-screen > *,
#content-container[class="reward-approval-screen"] > *,
#content-container.credit-approval-screen > *,
#content-container.email-subscription-screen > *,
#content-container.topup-history-screen > * {
  min-width: 1100px;
  max-width: 1400px;
}

#global-loading {
  z-index: 10000;
  position: absolute;
  background-color: white;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.att-file-label-container .ant-image {
  display: none;
}

.att-file-label-container > div:first-child {
  width: fit-content;
}

.att-file-label-container > div:first-child:hover {
  color: #40a9ff;
}

.ant-back-top {
  bottom: 50% !important;
  right: 40px !important;
}

div.custom-ant-input-container .ant-input::placeholder {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: var(--font-lightgray) !important;
}

div.custom-ant-input-container .ant-input {
  border-radius: 10px;
  height: 48px;
}

div.custom-ant-primary-button-container button {
  border-radius: 10px;
  height: 48px;
  background: var(--primary-blue);
}

div.custom-ant-primary-button-container button:hover,
div.custom-ant-primary-button-container button:focus {
  border-radius: 10px;
  height: 48px;
  background: var(--primary-blue);
  opacity: 0.8;
}

div.custom-ant-primary-button-container button > span {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  text-align: center;
  color: #ffffff;
}

div.custom-ant-input-password-container .ant-input-affix-wrapper {
  height: 48px;
  border-radius: 10px;
}

div.custom-ant-input-password-container input::placeholder {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: var(--font-lightgray);
}

/* i-Credit Approval */
.members-table-container {
  background-color: white;
  padding: 5px 0px 20px 0px;
  border-radius: 8px;
  margin-top: 15px;
}
.members-table-container .ant-table-tbody > tr > td,
.members-table-container .ant-table-thead > tr > th {
  border: none;
}
.members-table-container
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan]):before {
  display: none;
}

/* border radius for table row */
.members-table-container tr th:first-child,
.members-table-container tr td:first-child {
  border-radius: 8px 0 0 8px !important;
}

.members-table-container tr th:last-child,
.members-table-container tr td:last-child {
  border-radius: 0 8px 8px 0 !important;
}

/* table spacing between row */
.members-table-container table {
  border-collapse: separate;
  border-spacing: 0 10px !important;
}

/* table header color  */
.members-table-container table tr th {
  background-color: #f1f2f6 !important;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #939393;
}

/* space between table header and table body */
.members-table-container tbody:before {
  /* content: " "; */
  display: block;
  height: 1px;
}

.members-table-container .review-members-button {
  height: 38px;
  width: 156px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
}
.members-table-container .export-download-button {
  height: 38px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
}
/* i-Credit Approval */
